.container {
  width: 80%;
  margin: 1rem auto;

  @media screen and (max-width: 991px) {
    width: 100%;
    margin: auto;
  }

  button[type="submit"] {
    float: right;
    padding: 8px 12px;
    color: #ffffff;
    background-color: rgb(118, 189, 29);
    border: none;
    border-radius: 5px;
    min-width: 120px;
  }

  .text_area {
    width: 100%;
    border-radius: 5px;
    font-size: 16px;
    background-color: #f5f5f5;
    border: 1px solid #d2d2d2d2;
    padding: 0 10px;
    height: 200px;
  }

  select {
    .options {
      padding: 1rem;
    }
  }
}

.active_category {
  font-weight: bold;
  background-color: #555555;
  color: #ffffff;
}

.labels {
  font-weight: bold;
}

label {
  position: relative;
}

.input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #d2d2d2d2;
  height: 33px;
  font-size: 16px;
  background-color: #f5f5f5;
  padding: 0 10px;

  & + p {
    margin: 0;
  }
}

.search_location_box {
  width: 100%;
  background-color: #f5f5f5;
  font-size: 16px;
  max-height: 320px;
  overflow-y: scroll;
  padding: 1rem;

  p {
    padding: 8px 0;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px solid #d2d2d2d2;
    }

    &:hover {
      font-weight: bold;
    }
  }
}

.chipzone {
  background-color: #f3f3f3;
  padding: 1rem;
}