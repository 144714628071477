@font-face {
    font-family: 'Red Hat Display', sans-serif;
    src: url('https://webfonts.s3.eu-de.cloud-object-storage.appdomain.cloud/RedHatDisplay/RedHatDisplay-Medium.ttf') format('ttf'); /* and this for every font */
}


body {
    background-color: #f5f5f5;
    color: #555555 !important;
    margin: 0;
    font-family: 'Red Hat Display', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: 'Red Hat Display', sans-serif;
}

[list] {
  background-color: #fff;
  border: 1px solid #000;
  padding: 8px;
  max-height: 180px !important;
  overflow-y: auto !important;
}
