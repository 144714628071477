.container {
  border-radius: 8px 8px 0 0;

  @media screen and (max-width: 991px) {
    display: none;
  }

  .list {
    width: 91%;
    margin: 0 auto;

    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }

  .account_name {
    color: #E14206;
    font-weight: bolder;
    font-size: 2vw;
    max-width: 100px;
  }

  ul {

    li {
      display: flex;
      justify-items: center;
      align-items: center;
      margin-top: 10px !important;
      border-radius: 10px 10px 0 0;
      height: 48px;
      cursor: pointer;
      font-weight: bold;
      padding: 0 15px;

      button {
        outline: none !important;

        &::before {
          border: none !important;
        }
      }
    }

    // button styles advanced
    .my_space_button {
      background-color: #E14206;
      color: #ffffff;
    }
  }
}

.active_link {
  background-color: #76bd1d;
  color: #ffffff !important;
}