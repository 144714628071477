.footer {
  padding: 1rem 2rem;
  background-color: #707070;
  color: #ffffff;
}

.copy_right {
  padding: 1rem 3rem;
  background-color: #232323;
  color: #ffffff;

  .copy_right_highlight {
    color: #E14206;
  }
}

.link {
  a {
    color: #ffffff;
  }
}
