.container {
  @media screen and (max-width: 991px) {
    width: 100%;
  }
  .title {
    margin: 1rem 0;
  }
  .form_components {
    button[type="submit"] {
      color: #ffffff;
      background-color: rgb(118, 189, 29);
      border: none;
      border-radius: 5px;
      padding: 8px 2rem;
    }
  }
}

.input {
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  background-color: #f5f5f5;
  border: 1px solid #d2d2d2d2;
  padding: 0 10px;
  height: 33px;
  &[id="email"] {
    text-transform: lowercase;
  }
}

.password_box {
  position: relative;

  svg {
    position: absolute;
    transform: translate(-34px, 6px);
    cursor: pointer;
    font-size: 22px;
  }
}
