.container {
  width: 60%;
  @media screen and (max-width: 991px) {
    width: 92%;
  }
  margin: 4% auto;
  .title {
    margin: 1rem 0;
  }
}

.input {
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  background-color: #f5f5f5;
  border: 1px solid #d2d2d2d2;
  padding: 0 10px;
  height: 33px;
}

.submit_button {
  color: #ffffff;
  background-color: rgb(118, 189, 29) !important;
  border: none;
  border-radius: 5px;
}

select {
  width: 100%;
  background-color: #f5f5f5;
  border: 1px solid #d2d2d2d2;
  border-radius: 5px;
  font-size: 16px;
  padding: 0 7px;
  height: 33px;
  background-position-x: 0;
}
