.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  .title {
    background-color: #00AEBC;
    font-weight: bold;
    color: #ffffff;
    flex: 1;
    padding: 1rem;
  }
  .option {
    flex: 1;
    border-bottom: 2px solid #00AEBC;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  @media screen and (max-width: 991px) {
    .title {
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
        -webkit-box-orient: vertical;
      }
    }
  }
}

.smallScreenOption {
  display: none;
  @media screen and (max-width: 991px){
    display: flex;
  }
}

.largeScreenOption {
  display: flex;
  @media screen and (max-width: 991px){
    display: none;
  }
}
