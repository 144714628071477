.container {
  padding: 1rem 0;
  width: 90%;
  margin: 0 auto;

  @media screen and (max-width: 991px) {
    width: 100%;
  }
}

.button_session_logout {
  background-color: #e14206;
  color: #ffffff;
  border-radius: 7px;
  font-weight: bold;
  font-size: 2vw;
  cursor: pointer;
  height: 92px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1080px) {
    font-size: 3vw !important;
    padding: 1rem;
  }
}

.button_register_user {
  background-color: #e14206;;
  color: #ffffff;
  border-radius: 7px;
  cursor: pointer;
  font-size: 2.4vw;
  padding: 1rem;
  height: 92px !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1080px) {
    font-size: 3vw !important;
    padding: 1rem;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
}

.attributes_list {
  font-size: 14px;
  line-height: 25px;
}

.message_section {
  padding: 0 5rem;
  @media screen and (max-width: 991px) {
    margin-top: 1rem;
    padding: 0;
  }

  .message_section_alert {
    width: 47.45%;
    float: right;
    margin-bottom: 18px;
    @media screen and (max-width: 991px) {
      width: 100%;
      float: left;
    }
  }
}

.mobile_version {
  .logo_box {
    margin-left: 22px;
  }

  padding: 1rem;
  @media screen and (min-width: 1108px) {
    margin-bottom: -5%;
    display: none !important;
  }

  img[alt="menu-icon"] {
    cursor: pointer;
  }

  img[alt="account-icon"] {
    cursor: pointer;
  }

  .top_mobile_logo {
    text-align: center;

    img {
      height: 60px;
    }
  }
}

.large_screens {
  @media screen and (max-width: 1108px) {
    display: none !important;
  }
}

.sessions {
  margin-top: 6%;
  position: relative;

  ul {
    position: absolute;
    right: 8%;
  }
}

.logout_btn {
  svg {
    font-size: 32px;
    color: #F15A22;
    cursor: pointer;
    position: absolute;
  }
}

.box_one {
  background-color: #d2d2d2;
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  & > div {
    flex: 4
  }
  & > .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    & > svg {
      font-size: 1rem;
    }
  }
}

.box_two {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #76BD1D;
  color: #ffffff;
  font-size: 12px;
  overflow: hidden;
  max-height: 80px;

  img {
    width: 74px;
  }
}

.box_three {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  background-color: #76BD1D;
  color: #ffffff;
  font-size: 12px;
  overflow: hidden;
  max-height: 80px;

  img {
    width: 80px;
    height: 33px;
  }

  div {
    padding: 10px;
    background-color: #fff;
    color: #555;
    border-radius: 10px;
    width: 100%;
    text-align: center;
    font-weight: bold;
  }
}

.box_four {
  background-color: #e14206;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  font-size: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1080px) {
    font-size: 3vw;
  }
}


