.container {
  padding: 4em 1em;
  .input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #d2d2d2d2;
    height: 33px;
    font-size: 16px;
    background-color: #f5f5f5;
    padding: 0 10px;
  }

  .textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #d2d2d2d2;
    min-height: 300px;
    font-size: 16px;
    background-color: #f5f5f5;
    padding: 0 10px;
  }

  .btn_communicate {
    border-radius: 18px;
    background-color: #76bd1d;
    padding: 6px 12px;
    color: #ffffff;
    border: none;
  }

  .btn_close {
    border-radius: 18px;
    background-color: #707070;
    padding: 6px 12px;
    color: #ffffff;
    border: none;
  }

}
