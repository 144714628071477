.input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #d2d2d2d2;
    height: 33px;
    font-size: 16px;
    background-color: #f5f5f5;
    padding: 0 10px;

    & + p {
      margin: 0;
    }
}

.container {
    .service_block_options {
        padding: 1rem 0.2rem;
        .service_block_options_option {
            border-bottom: 1px solid #d3d3d3;
            cursor: pointer;
            padding: 6px 0;
        }
    }
}
