.container {
  .form_components {
    button[type="submit"] {
      padding: 8px 12px;
      color: #ffffff;
      background-color: #00aebc;
      min-width: 170px;
      border: none;
      border-radius: 5px;
    }
  }
}

.input {
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  background-color: #f5f5f5;
  border: 1px solid #d2d2d2d2;
  padding: 0 10px;
  height: 33px;
}
