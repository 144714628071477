.container {
  width: 100%;
  height: 920px;
  position: absolute;
  .cover {
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    opacity: 0.8;
    border-radius: inherit;
  }
  img {
    height: 100%;
    width: 100%;
    z-index: 1;
    object-fit: fill;
    border-radius: inherit;
  }
  @media screen and (max-width: 991px) {

  }
}
