.error {
  background-color: #f50c0c;
}

.error_input {
  border: 2px solid #f50c0c!important;
}

.error_text {
  color: #f50c0c;
}

.success {
  background-color: #026202;
}

.success_input {
  border: 2px solid #026202;
}

.success_text {
  color: #026202;
}

.warning {
  background-color: #d29e65;
}

.warning_input {
  border: 2px solid #d29e65;
}

.warning {
  color: #d29e65;
}

