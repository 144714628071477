.input {

}

.options {
  padding: 0.475rem 0;
  background-color: #FFF;
  border-bottom: 2px solid rgb(118, 189, 29);
  li {
    cursor: pointer;
    &:hover {
      span {
        color: rgb(118, 189, 29)!important;
      }
    }
  }
}