.container {
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  background-color: #f5f5f5;
  border: 1px solid #d2d2d2d2;
  padding: 0;
  height: 33px;
  button {
    border: none;
    background-color: inherit;
    height: 100%;
    width: 100%;
  }
}
