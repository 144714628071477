.container {
  width: 50%;
  text-align: center;
  position: relative;
  margin: 0 auto;
  .input {
    border-radius: 23px;
    background-color: #f5f5f5;
    border: 1px solid #d2d2d2d2;
    height: 33px;
    font-size: 16px;
    padding: 0 16px;
    margin: 1rem auto;
    width: 100%;
    &:focus {
      outline: none;
      box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28);
      & + i {
        display: none;
      }
    }
  }
  i {
    position: absolute;
    right: 14px;
    top: 25px;
  }
  @media screen and (max-width: 991px){
    width: 70%;
    margin: 0 12%;
  }
}
