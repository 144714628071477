.container {
  border-radius: 8px 8px 0 0;
  @media screen and (max-width: 991px){
    display: none;
  }
  ul {
    li {
      margin-top: 10px!important;
      border-radius: 10px 10px 0 0;
      height: 48px;
      cursor: pointer;
      font-weight: bold;
      padding: 0 15px;
    }
    // button styles advanced
    .my_space_button {
      background-color: #E14206;
      color: #ffffff;
    }
  }
}

.active_link {
  background-color: #ffffff;
  color: #e24307;
}
