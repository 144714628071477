.container {
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      margin: 12px 0;

      &:last-child {
        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
          display: flex;

          li {
            padding-right: 10px;
            cursor: pointer;
            color: #e24307;

            &:first-child {
              border-right: 1px solid #d2d2d2;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

.input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #d2d2d2d2;
  height: 33px;
  font-size: 16px;
  background-color: #f5f5f5;
  padding: 0 10px;

  &+p {
    margin: 0;
  }

  &[id="login_username"] {
    text-transform: lowercase;
  }
}

.grid {
  display: grid;
  grid-template-columns: 5fr 1fr;
  grid-gap: 2em;
}

.grid_one {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2em;
}

.login_btn {
  color: #ffffff;
  border-radius: 5px;
  background-color: #76bd1d;
  font-size: 15px;
  box-shadow: none;
  border: 1px solid #d2d2d2;
  padding: 5.4px;
  float: right;
  width: 100%;
  cursor: pointer;

  &:active {
    border: 2px solid #151515;
  }
}

.password_box {
  position: relative;

  svg {
    position: absolute;
    transform: translate(-28px, 6px);
    cursor: pointer;
    font-size: 22px;
  }
}