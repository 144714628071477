.container {
  width: 60%;
  @media screen and (max-width: 991px) {
    width: 92%;
  }
  margin: 4% auto;
  .title {
    margin: 1rem 0;
  }
  .form_components {
    button[type="submit"] {
      padding: 8px 12px;
      color: #ffffff;
      background-color: rgb(118, 189, 29);
      border: none;
      border-radius: 5px;
    }
  }
}

.input {
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  background-color: #f5f5f5;
  border: 1px solid #d2d2d2d2;
  padding: 0 10px;
  height: 33px;
}

button[type="submit"] {
  min-width: 120px;
}
