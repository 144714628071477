.container {

  .title {
    margin: 1rem 0;
  }

  .form_components {
    button[type="submit"] {
      padding: 8px 12px;
      color: #ffffff;
      background-color: rgb(118, 189, 29);
      border: none;
      border-radius: 5px;
    }
  }
}

.input {
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  background-color: #f5f5f5;
  border: 1px solid #d2d2d2d2;
  padding: 0 10px;
  height: 33px;
}

button[type="submit"] {
  float: right;
  padding: 8px 12px;
  color: #ffffff;
  background-color: rgb(118, 189, 29) !important;
  border: none;
  border-radius: 5px;
  min-width: 120px;
}

.password_box {
  position: relative;

  svg {
    position: absolute;
    transform: translate(-28px, 6px);
    cursor: pointer;
    font-size: 22px;
  }
}

.labels {
  font-weight: bold;
}
