.container {
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.16);
  background-color: #f5f5f5;
  position: fixed;
  width: 80%;
  height: 100vh;
  z-index: 9999;
  display: block;
  transition-property: width;
  transition-duration: 2s;
  @media screen and (min-width: 991px) {
    display: none;
  }

  ul {
    list-style-type: none !important;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      border-bottom: 1px solid #7e7e7e;
      font-weight: bold;
      padding: 1rem 0 1rem 1rem;
      cursor: pointer;

      &:first-child {
        text-align: right;
        border-bottom: none;
        padding: 18px;

        svg {
          font-size: 30px;
        }
      }
    }
  }
}

.text_list {
  div {
    background-color: #00AEBC;
    color: #ffffff;
    font-weight: bold;
    width: 100%;
    // border-top-right-radius: 32px;
    // border-bottom-right-radius: 32px;
    padding: 1rem;
  }
  padding: 0!important;
  border-bottom: none!important;
}

.bottom_buttons {
  width: 80%;
  margin: 2rem auto;
  button {
    outline: none;
    border: none;
    padding: 1rem;
    &:first-child {
      background-color: #76BD1D;
      border-radius: 7px;
      color: #ffffff;
    }
    &:nth-child(2) {
      background-color: #00AEBC;
      border-radius: 7px;
      color: #ffffff;
    }

    &:nth-child(3) {
      background-color: #E14206;
      border-radius: 7px;
      color: #ffffff;
    }
  }
}

.login_form {
  padding: 2rem 1rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .back_icon {
    font-size: 28px;
    cursor: pointer;
    position: absolute;
    top: 5%;
    left: 5%;
  }

  form {
    width: 100%;
  }
}
